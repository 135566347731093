<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="账号" prop="account">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              :placeholder="form.guid ? '为空则不更新原密码' : ''"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号有效期">
            <el-date-picker
              v-model="form.expireDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="角色">
            <el-radio-group v-model="form.role">
              <el-radio
                v-for="item in roleList"
                :key="item.key"
                :label="item.key"
                @change="mustProperty(item.value)"
                >{{ item.value }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联项目" v-if="form.role == 2">
            <el-card class="box-card" style="width:100%" shadow="none">
              <div>
                <el-transfer
                  :titles="['未关联', '已关联']"
                  :button-texts="['取消关联', '添加关联']"
                  filterable
                  :filter-method="filterMethod"
                  filter-placeholder="请输入"
                  v-model="form.projectGuidList"
                  :data="projectList"
                >
                </el-transfer>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item v-if="form.role == 3" label="物业公司">
            <el-select
              v-model="form.companyGuid"
              filterable
              placeholder="请选择物业公司"
            >
              <el-option
                v-for="company in companyList"
                :key="company.guid"
                :label="company.name"
                :value="company.guid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责区域" v-if="form.role == 3">
            <el-card class="box-card" style="width:80%" shadow="none">
              <el-button type="primary" @click="addOption" size="mini"
                >添加区域</el-button
              >
              <el-table :data="form.communityList" style="width: 100%">
                <el-table-column prop="provinceName" label="省"></el-table-column>
                <el-table-column prop="cityName" label="市"></el-table-column>
                <el-table-column prop="districtName" label="区"></el-table-column>
                <el-table-column prop="streetName" label="街道"></el-table-column>
                <el-table-column prop="committeeName" label="居委"></el-table-column>
                <el-table-column prop="communityName" label="小区"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="delRow(scope.row.guid)">移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div
                v-for="o in form.communityList"
                :key="o.guid"
                class="text item hehe"
                style="margin-top: 20px"
              >
              </div>
            </el-card>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button
            >
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog title="选择小区" :visible.sync="dialogVisible" width="50%">
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="provinceGuid"
            @change="provinceChange()"
            placeholder="省"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="cityGuid"
            @change="getDistrictList()"
            placeholder="市"
          >
            <el-option
              v-for="item in cityList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="districtGuid"
            @change="getStreetList()"
            placeholder="区"
          >
            <el-option
              v-for="item in districtList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="streetGuid"
            @change="getCommitteeList()"
            placeholder="街道"
          >
            <el-option
              v-for="item in streetList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="committeeGuid"
            @change="getCommuntyList()"
            placeholder="居委会"
          >
            <el-option
              v-for="item in committeeList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="communityGuid"
            @change="communityChange()"
            placeholder="小区"
          >
            <el-option
              v-for="item in communityList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOption()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        guid: "",
        name:"",
        phone: "",
        account: "",
        password: "",
        expireDate: "",
        role: "",
        communityList: [],
        communityGuidList: [],
        districtGuidList: [],
        streetGuidList: [],
        projectGuidList: [],
        companyGuid: "",
      },
      userInfo: {},
      allDeptPosition: [],
      rules: {},
      roleList: [
        { key: 1, value: "青蓝管理人员" },
        { key: 2, value: "委托方" },
        { key: 3, value: "物业经理" },
      ],
      companyList: [],
      provinceList: [],
      cityList: [],
      committeeList: [],
      communityList: [],
      districtList: [],
      streetList: [],
      dialogVisible: false,
      provinceGuid: "",
      provinceName: "",
      cityGuid: "",
      cityName: "",
      districtGuid: "",
      districtName: "",
      streetGuid: "",
      streetName: "",
      committeeGuid: "",
      committeeName: "",
      communityGuid: "",
      communityName: "",
      communityGuidList: [],
      projectList: [],
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserDetail(self.$route.query.guid || null);
    // self.getDistrictList();
    self.getProvinceList();
    self.getProjectList();
    self.getCompanyList();
  },
  methods: {
    getUserDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/admin/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            // self.form = response.data.data;
            self.form.guid = response.data.data.guid;
            self.form.account = response.data.data.account;
            self.form.name = response.data.data.name;
            self.form.password = "";
            self.form.phone = response.data.data.phone;
            // self.$set('form', 'password', '');
            self.form.expireDate = response.data.data.expireDate;
            self.form.role = response.data.data.role;
            self.form.companyGuid = response.data.data.companyGuid;
            self.form.communityGuidList = [];
            self.form.districtGuidList = [];
            self.form.streetGuidList = [];
            self.form.communityList = response.data.data.areaRelationList;
            if (self.form.communityList == null) {
              self.form.communityList = [];
            }
            self.form.communityList.forEach((item) => {
              if (item.guid != "") {
                self.form.communityGuidList.push(item.guid);
              } else if (item.streetGuid != "") {
                self.form.streetGuidList.push(item.streetGuid);
              } else if (item.districtGuid != "") {
                self.form.districtGuidList.push(item.districtGuid);
              }
            });
            self.form.projectGuidList = self.$underscore.map(
              response.data.data.projectList,
              function(_el) {
                return _el.guid;
              }
            );
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    getCompanyList() {
      let self = this;
      self.$axios
        .get("/company/list/", {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.companyList = response.data.data;
        })
        .catch(function(error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    mustProperty(value) {
      let self = this
      if(value == '物业经理') {
        self.rules = {
        phone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误', trigger: "blur" }  
        ]
        }
      } else {
        self.rules = {
          phone: [{required: false}]
        }
      }
    },
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          self.form.areaRelationList = self.form.communityList;
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/admin/saveOrUpdate", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function() {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "admin_list" });
            })
            .catch(function(error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的管理员信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/admin/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "admin_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    delRow(guid) {
      let self = this;
      self.form.communityList = self.form.communityList.filter(
        (value) => value.guid != guid
      );
      self.communityGuidList = self.communityGuidList.filter(
        (value) => value !== guid
      );
    },
    addOption() {
      let self = this;
      self.provinceGuid = "";
      self.provinceName = "";
      // self.provinceList = [];
      self.cityGuid = "";
      self.cityName = "";
      self.cityList = [];
      self.districtGuid = "";
      self.districtName = "";
      self.districtList = [];
      self.streetList = [];
      self.streetGuid = "";
      self.streetName = "";
      self.committeeGuid = "";
      self.committeeName = "";
      self.committeeList = [];
      self.communityGuid = "";
      self.communityName = "";
      self.communityList = [];
      self.dialogVisible = true;
    },
    doAddOption() {
      let self = this;
      if (
        self.form.communityGuidList.indexOf(self.communityGuid) > -1 &&
        self.form.districtGuidList.indexOf(self.districtGuid) > -1 &&
        self.form.streetGuidList.indexOf(self.streetGuid) > -1
      ) {
        self.$message({
          message: "请勿添加重复项",
          type: "warning",
        });
        return;
      }
      if (self.communityGuid == "" && self.form.role == 3) {
        self.$message({
          message: "物业经理负责区域必须精确到小区",
          type: "warning",
        });
        return;
      }
      let params = {
        provinceGuid: self.provinceGuid,
        provinceName: self.provinceName,
        cityGuid: self.cityGuid,
        cityName: self.cityName,
        districtName: self.districtName,
        districtGuid: self.districtGuid,
        streetName: self.streetName,
        streetGuid: self.streetGuid,
        committeeGuid: self.committeeGuid,
        committeeName: self.committeeName,
        communityName: self.communityName,
        communityGuid: self.communityGuid,
      };
      self.form.communityList.push(params);

      if (self.communityGuid != "") {
        self.form.communityGuidList.push(self.communityGuid);
      } else if (self.streetGuid != "") {
        self.form.streetGuidList.push(self.streetGuid);
      } else if (self.districtGuid != "") {
        self.form.districtGuidList.push(self.districtGuid);
      }
      self.dialogVisible = false;
    },
    getProvinceList() {
      let self = this;
      self.$axios
        .get("/province/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.provinceList = response.data.data;
        })
        .catch(function(error) {
          self.$message({
            message: "省列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    provinceChange() {
      let self = this;
      self.getCityList(self.provinceGuid);
      self.provinceName = self.$underscore.find(self.provinceList, function(
        _el
      ) {
        return _el.guid == self.provinceGuid;
      }).name;
    },
    getCityList() {
      let self = this;
      self.$axios
        .get("/city/list?provinceGuid=" + self.provinceGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.cityList = response.data.data;
          self.cityGuid = ""
          self.districtGuid = ""
          self.streetGuid = ""
          self.committeeGuid = ""
          self.communityGuid = ""
        })
        .catch(function(error) {
          self.$message({
            message: "城市列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getDistrictList() {
      let self = this;
      self.$axios
        .get("/district/list?cityGuid=" + self.cityGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.cityName = self.$underscore.find(self.cityList, function(_el) {
            return _el.guid == self.cityGuid;
          }).name;
          self.districtList = response.data.data;
          self.districtGuid = ""
          self.streetGuid = ""
          self.committeeGuid = ""
          self.communityGuid = ""
        })
        .catch(function(error) {
          self.$message({
            message: "区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getStreetList() {
      let self = this;
      self.$axios
        .get("/street/list?districtGuid=" + self.districtGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.streetList = response.data.data;
          self.districtName = self.$underscore.find(self.districtList, function(
            _el
          ) {
            return _el.guid == self.districtGuid;
          }).name;
          self.streetGuid = ""
          self.committeeGuid = ""
          self.communityGuid = ""
        })
        .catch(function(error) {
          self.$message({
            message: "街道列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getCommitteeList() {
      let self = this;
      self.$axios
        .get("/committee/list?streetGuid=" + self.streetGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.committeeList = response.data.data;
          self.streetName = self.$underscore.find(self.streetList, function(
            _el
          ) {
            return _el.guid == self.streetGuid;
          }).name;
          self.committeeGuid = ""
          self.communityGuid = ""
        })
        .catch(function(error) {
          self.$message({
            message: "居委会列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getCommuntyList() {
      let self = this;
      self.$axios
        .get("/community/list?committeeGuid=" + self.committeeGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.communityList = response.data.data;
          self.committeeName = self.$underscore.find(
            self.committeeList,
            function(_el) {
              return _el.guid == self.committeeGuid;
            }
          ).name;
          self.communityGuid = ""
        })
        .catch(function(error) {
          self.$message({
            message: "社区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    communityChange() {
      let self = this;
      self.communityName = self.$underscore.find(self.communityList, function(
        _el
      ) {
        return _el.guid == self.communityGuid;
      }).name;
    },
    getProjectList() {
      let self = this;
      self.$axios
        .get("/project/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function(response) {
          self.projectList = self.$underscore.map(response.data.data, function(
            _el
          ) {
            return { key: _el.guid, label: _el.name };
          });
        })
        .catch(function(error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    filterMethod(query, item) {
      // return true;
      return item.label.indexOf(query) > -1;
    },
  },
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
